<template>
  <div class="set-block">
    <div
      v-if="!header"
      class="set-block__header"
      :class="{ 'set-block__header_disable': !!disable }"
    >
      <div class="set-block__header-content">
        <div
          class="set-block__title"
          :class="{ 'set-block__title_disable': !!disable }"
        >
          {{ title }}
        </div>
        <div class="set-block__text">
          {{ subTitle }}
        </div>
      </div>
      <div class="d-flex mr-auto align-items-center">
        <div v-if="responsible" class="responsibles ml-md-4 ml-2">
          <div class="responsibles__title">{{ $t('responsibles') }}</div>
          <div class="responsibles__value">4/64</div>
        </div>
        <RButton
          size="xxs"
          color="transparent"
          class="ml-md-4 ml-2"
          @click="emit('clickQuestionAnswer')"
        >
          <nuxt-icon name="chat" class="sprite-dark-blue-to-blue-background" />
        </RButton>
        <RButton
          size="xxs"
          color="transparent"
          class="ml-md-4 ml-2"
          :counter="countButton && countButton"
          @click="emit('clickShare')"
        >
          <nuxt-icon
            id="popcorn"
            name="chat"
            class="sprite-dark-blue-to-blue-background"
          />
        </RButton>
        <perfect-scrollbar
          v-if="opMod"
          id="tooltip"
          ref="scroll_element"
          v-click-outside="onClickOutside"
          :settings="settings"
          class="custom-share-popup"
        >
          <popup-share-no-background />
        </perfect-scrollbar>
        <RButton
          size="xxs"
          color="transparent"
          class="ml-md-6 ml-2 shevron-icon"
          :class="{
            'shevron-icon_active': openBlockWrapper,
          }"
          @click="emit('clickChevron')"
        >
          <nuxt-icon name="chevron_up" />
        </RButton>
      </div>
    </div>
    <div v-if="openBlockWrapper" class="set-block__body">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import RButton from '@/libs/ui_components/RButton/RButton.vue'
import NuxtIcon from '@/libs/components/common/NuxtIcon.vue'
import PopupShareNoBackground from '@/libs/components/popup/PopupShareNoBackground.vue'

const emit = defineEmits([
  'clickPopupShare',
  'clickQuestionAnswer',
  'clickShare',
  'clickChevron',
])

// interface Props {
//   title: string
//   subTitle: string
//   disable: boolean
//   openBlockWrapper: boolean
//   responsible: string
//   countButton: string
//   header: string
// }

// const props = withDefaults(defineProps<Props>(), {
//   title: '',
//   subTitle: '',
//   disable: false,
//   openBlockWrapper: true,
//   responsible: '',
//   countButton: '',
//   header: '',
// })
const opMod = ref(false)
const onClickOutside = () => {
  opMod.value = false
}
// const openPopupWindow = () => {
//   emit('clickPopupShare', true)
// }

const settings = {}
</script>
<style lang="scss" scoped>
#popper[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter .modal-dialog,
.fade-leave-to .modal-dialog {
  transform: translateY(-20%);
}
.shevron-icon {
  transform: rotate(90deg);
}
.shevron-icon_active {
  transform: rotate(180deg);
}
.custom-share-popup {
  width: auto;
  height: 350px;
  background-color: #fff;
  box-shadow: $shadow-lvl-6;
  position: absolute;
  z-index: 11;
  top: 30px;
  left: 15px;
}
.set-block {
  position: relative;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  filter: drop-shadow(0px 3px 8px rgba(56, 69, 93, 0.03))
    drop-shadow(0px 1.5px 3px rgba(56, 69, 93, 0.04))
    drop-shadow(0px 0.5px 1px rgba(56, 69, 93, 0.1));
  &__header {
    border-radius: 4px 4px 0 0;
    background-color: $sub-light-blue;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
  }
  &__header_disable {
    border-radius: 4px 4px 0 0;
    background-color: $sub-grey;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $dark-blue;
  }
  &__title_disable {
    color: $sub-dark;
  }
  &__text {
    font-size: 12px;
    line-height: 16px;
    color: $dark-blue;
  }
  &__body {
    border-radius: 0 0 4px 4px;
    padding: 24px;
    background-color: #fff;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px;
  }
  &__col {
    padding: 0 32px;
    width: calc(100% / 3);
  }
}
.responsibles {
  text-align: left;
  &__title {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: $dark-blue;
  }
  &__value {
    margin-top: -6px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $dark-blue;
  }
}
@media (max-width: 1199px) {
  .set-block {
    &__row {
      margin-left: -12px;
      margin-right: -12px;
    }
    &__col {
      padding: 0 12px;
    }
  }
}
@media (max-width: 767px) {
  .set-block {
    &__header {
      min-height: 60px;
      height: auto;
      padding: 12px 8px;
    }
    &__header-content {
      margin-left: 8px;
    }
    &__title {
      font-size: 16px;
      line-height: 20px;
    }
    &__body {
      padding: 16px 8px;
    }
    &__row {
      margin-bottom: -16px;
    }
    &__col {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
</style>
